import React from 'react'
import logo from '../components/logo.svg'
import { Link } from 'gatsby'
import { rhythm } from '../utils/typography'

const NotFoundPage = () => (
  <div css={{
    display: `flex`,
    flexDirection: 'column',
    alignItems: `center`,
    justifyContent: 'center',
    paddingTop: rhythm(1),
    color: '#fff',
    height: '100vh'
  }}>
    <img
        src={logo}
        alt={`Chris Feddersen's Logo`}
        css={{
          marginRight: rhythm(1 / 2),
          marginBottom: rhythm(2),
          height: rhythm(4),
          width: rhythm(4),
          display: `block`,
        }}
      />
    <h1 css={{
      color: '#fff'
    }}>404: NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist</p>
    <Link
        to={'/'}
      >
       Return to Home
      </Link>
  </div>
)

export default NotFoundPage
